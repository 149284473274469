<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="执行器">
            <el-select v-model="form.jobGroup">
              <el-option
                v-for="(item, index) in baseData.jobgroups"
                :key="index"
                :value="item.dictKey"
                :label="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务描述">
            <el-input placeholder="请输入" v-model="form.jobDesc" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" plain @click="handleEdit('add')">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          border
          stripe
          height="100%"
        >
          <el-table-column prop="id" label="任务ID" align="center" min-width="120px" />
          <el-table-column prop="jobDesc" label="任务名" align="center" min-width="150px"></el-table-column>
          <el-table-column prop="scheduleType" label="调度类型" align="center" min-width="200px">
            <template slot-scope="scope">
              <span
                v-if="scope.row.scheduleType === 'CRON' || scope.row.scheduleType === 'FIX_RATE'"
              >{{ scope.row.scheduleType }}:{{ scope.row.scheduleConf }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="scheduleConfDesc" label="调度频率" align="center" min-width="200px">
            <template slot-scope="scope">
              <span>{{ scope.row.scheduleConfDesc || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit('edit',scope.row)">编辑</el-button>
              <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :page-size="page.size"
          :current-page="page.current"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </template>
    </common-layout>

    <add-modal
      :show.sync="showAdd"
      :tenantId="params.tenantId"
      :baseData="baseData"
      :type="formType"
      :detail="curRow"
      @close="closeModal"
    />
  </div>
</template>
<script>
import api from '@/api/devops/job'
import addModal from './addModal'

export default {
  components: { addModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      showAdd: false,
      curRow: null,
      formType: 'add',
      baseData: {},
      jobgroups: [],
      params: {
        globalOrLocal: 'global',
        tenantId: 'back_end',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      // 执行器
      let { data: jobgroups } = await api.getDict({
        ...this.params,
        dictKey: 'jobgroup',
      })
      jobgroups.forEach((item) => (item.dictKey = Number(item.dictKey)))
      this.baseData.jobgroups = jobgroups
      jobgroups.length && this.$set(this.form, 'jobGroup', jobgroups[0].dictKey)

      // 负责人
      let { data: userList } = await api.getUserAccount({
        current: 1,
        size: 1000,
      })
      this.baseData.userList = userList.records

      this.query()
    },
    query() {
      let params = {
        ...this.form,
        ...this.page,
        triggerStatus: -1,
      }
      this.tableLoading = true
      api
        .getList(params)
        .then((res) => {
          this.tableData = res.data.data
          this.total = res.data.recordsTotal
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleQuery() {
      this.page.current = 1
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    async handleEdit(type, row) {
      this.type = type
      // 调度类型
      let { data: scheduleTypes } = await api.getDict({
        ...this.params,
        dictKey: 'schedule_type',
      })
      this.baseData.scheduleTypes = scheduleTypes
      // 路由策略
      let { data: jobconfRoutes } = await api.getDict({
        ...this.params,
        dictKey: 'jobconf_route',
      })
      this.baseData.jobconfRoutes = jobconfRoutes
      // 调度过期策略
      let { data: misfireStrategies } = await api.getDict({
        ...this.params,
        dictKey: 'misfire_strategy',
      })
      this.baseData.misfireStrategies = misfireStrategies
      // 阻塞处理策略
      let { data: jobconfBlocks } = await api.getDict({
        ...this.params,
        dictKey: 'jobconf_block',
      })
      this.baseData.jobconfBlocks = jobconfBlocks
      this.curRow = _.cloneDeep(row)
      this.formType = type
      this.showAdd = true
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
  },
}
</script>
