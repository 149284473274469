<template>
  <el-dialog
    :title="type == 'add' ? '新增任务调度' : '编辑任务调度'"
    width="720px"
    :visible="show"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form label-width="100px" inline :model="form" :rules="rules" ref="form">
      <div class="common-header">基础配置</div>
      <el-form-item label="执行器" prop="jobGroup" required>
        <el-select v-model="form.jobGroup">
          <el-option
            v-for="(item, index) in baseData.jobgroups"
            :key="index"
            :value="item.dictKey"
            :label="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务描述" prop="jobDesc" required>
        <el-input placeholder="请输入" v-model="form.jobDesc" clearable></el-input>
      </el-form-item>
      <el-form-item label="报警邮件" prop="alarmEmail">
        <el-input placeholder="请输入" v-model="form.alarmEmail" clearable></el-input>
      </el-form-item>
      <div class="common-header">调度配置</div>
      <el-form-item label="调度类型" prop="scheduleType" required>
        <el-select v-model="form.scheduleType">
          <el-option
            v-for="(item, index) in baseData.scheduleTypes"
            :key="index"
            :value="item.dictKey"
            :label="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="CRON" v-show="form.scheduleType == 'CRON'" required>
        <el-input placeholder="请输入" v-model="form.scheduleConf" clearable style="width: 200px"></el-input>
        <el-popover v-model="cronPopover" placement="top" width="500" trigger="click">
          <cron @change="onChangeCron" @close="cronPopover = false"></cron>
          <el-button class="ml5" slot="reference" icon="el-icon-edit" type="primary" plain></el-button>
        </el-popover>
        <div class="cron-text">{{cronText}}</div>
      </el-form-item>
      <el-form-item label="固定速度" v-if="form.scheduleType == 'FIX_RATE'" required>
        <el-input placeholder="请输入" v-model="form.scheduleConf" clearable></el-input>
      </el-form-item>
      <div class="common-header">任务配置</div>
      <el-form-item label="运行模式" prop="glueType" required>
        <el-select v-model="form.glueType">
          <el-option value="BEAN" label="BEAN"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="JobHandler" prop="executorHandler" required>
        <el-input placeholder="请输入" v-model="form.executorHandler" clearable></el-input>
      </el-form-item>
      <el-form-item label="任务参数" prop="executorParam">
        <el-input
          placeholder="请输入"
          v-model="form.executorParam"
          clearable
          type="textarea"
          style="width: 510px"
        ></el-input>
      </el-form-item>
      <div class="common-header">高级配置</div>
      <el-form-item label="路由策略" prop="executorRouteStrategy" required>
        <el-select v-model="form.executorRouteStrategy">
          <el-option
            v-for="(item, index) in baseData.jobconfRoutes"
            :key="index"
            :value="item.dictKey"
            :label="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调度过期策略" prop="misfireStrategy" required>
        <el-select v-model="form.misfireStrategy">
          <el-option
            v-for="(item, index) in baseData.misfireStrategies"
            :key="index"
            :value="item.dictKey"
            :label="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="阻塞处理策略" prop="executorBlockStrategy" required>
        <el-select v-model="form.executorBlockStrategy">
          <el-option
            v-for="(item, index) in baseData.jobconfBlocks"
            :key="index"
            :value="item.dictKey"
            :label="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务超时时间" prop="executorTimeout" required>
        <el-input placeholder="任务超时时间单位秒，大于0时生效" v-model="form.executorTimeout" clearable></el-input>
      </el-form-item>
      <el-form-item label="失败重试次数" prop="executorFailRetryCount" required>
        <el-input placeholder="失败重试次数，大于0时生效" v-model="form.executorFailRetryCount" clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/devops/job'
import { cron } from 'vue-cron'
import cronstrue from 'cronstrue/i18n'

export default {
  components: { cron },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    type: {
      default: () => 'add',
      type: String,
    },
    baseData: {
      default: () => {},
      type: Object,
    },
    tenantId: {
      default: () => '',
      type: String,
    },
  },
  data() {
    return {
      rules: {},
      cronText: '',
      cronPopover: false,
      form: { scheduleConf: '' },
    }
  },
  computed: {
    dictKey() {
      return this.$route.query.dictKey
    },
  },
  watch: {
    show(val) {
      if (val) {
        if (this.type == 'add') {
          this.form = {
            status: 'enable',
          }
        } else {
          this.form = { ...this.detail }
        }
      } else {
        this.$refs['form'].resetFields()
      }
    },
    'form.scheduleConf'(value) {
      this.cronText = value ? this.getCronText(value) : ''
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = {
          ...this.form,
          author: 'back_end',
          scheduleConfDesc: this.cronText,
        }
        if (this.type == 'add') {
          params.tenantId = this.tenantId
        }
        const fn = this.type == 'add' ? api.add : api.update
        fn(params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
    onChangeCron(v) {
      this.form.scheduleConf = v
    },
    getCronText(cron) {
      const text = cronstrue.toString(cron, {
        locale: 'zh_CN',
      })

      return text
    },
  },
}
</script>
<style>
#changeContab .bottom .value {
  font-size: 12px !important;
  margin-right: 10px;
}
.cron-text {
  font-size: 12px;
  color: #999;
}
</style>
