import http from '@/utils/axios'
const api = {
  getDict: data => http.post(`/cmp-console/dict/admin/list`, data),
  getList: data => http.post(`/cmp-job-admin/basic/jobinfo/pageList`, data),
  add: data => http.post(`/cmp-job-admin/basic/jobinfo/add `, data),
  update: data => http.post(`/cmp-job-admin/basic/jobinfo/update`, data),
  stop: data => http.post(`/cmp-job-admin/jobinfo/stop`, data),
  start: data => http.post(`/cmp-job-admin/jobinfo/start`, data),
  delete: data => http.post(`/cmp-job-admin/basic/jobinfo/remove`, data),
  getUserAccount: data => http.get(`/tenant-user/user/front_desk/query`, data),
  trigger: data => http.post(`/cmp-job-admin/jobinfo/trigger`, data),
  log: data => http.post(`/cmp-job-admin/joblog/pageListBackEnd`, data),
  getTentantList: data =>
    http.get('/tenant-user/enterpriseAccount/back_end/query', data)
}
export default api

